import React from "react"
import { Link } from "gatsby"
import { Row, Col, Nav } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"

const Services = () => (
  <Layout>
    <SEO
      title={"Services"}
      titleDesc={
        "We offer a unique blend of onsite and online AI education program to enable the AI talents with the clients to develop cutting edge AI solutions."
      }
      titleKeywords={
        "fuse classroom, Best AI school, Admission in Machine Learning, AI micro masters program, AI courses, AI certification"
      }
      fbDes={
        "A unique blend of onsite and online AI program to enable the AI talents to develop forefront AI solutions."
      }
      twitDesc={
        "A unique blend of onsite and online AI program to enable the AI talents to develop forefront AI solutions."
      }
      seoLink={"services/"}
    />

    {/* top section */}
    <section role="top--section" className="service py-5">
      {/* background overlay */}
      <div className="bg-overlay" />

      {/* content */}
      <article className="container h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col sm={10} className="text-center text-white">
            <h1 className="font-weight-bold text-white">We help bring your classrooms online</h1>
            <p className="h4 mt-4">
              Our Education specialists and technology consultants take Colleges and Universities online from start to
              finish and beyond.
            </p>

            <div className="mt-5">
              <Link
                to="/request-for-more-info/"
                className="btn btn-warning mr-3 btn-lg"
                // onClick={() => FormEventAnalytics("Services_Top_Request_Demo")}
                id="request--for-more-info__top"
              >
                REQUEST FOR MORE INFO
              </Link>
            </div>
          </Col>
        </Row>
      </article>
    </section>
    {/* top section end */}

    {/* how we help button */}
    <section className="py-4 ">
      <article className="container">
        <Row>
          <Col sm="12" className="text-center">
            <h1 className="font-weight-bold my-4">How We Help</h1>

            <Nav className="justify-content-center">
              <Nav.Item className="border border-success h5 py-2 px-3">
                <Link to="/services/#access-your-need">Assess Your Need</Link>
              </Nav.Item>
              <Nav.Item className="border border-success h5 py-2 px-3">
                <Link to="/services/#customized-plan-and-conduct-training">
                  Create a Customized Plan and Conduct Training
                </Link>
              </Nav.Item>
              <Nav.Item className="border border-success h5 py-2 px-3">
                <Link to="/services/#ensure-successful-adoption">Ensure Successful Adoption</Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </article>
    </section>

    <section id="access-your-need" className="my-2">
      <article className="container-fluid">
        <Row className="align-items-center">
          <Col lg={{ span: 5, offset: 2, order: 2 }} md={{ span: 5, order: 2 }}>
            <img src="/images/access-your-need.webp" alt="images" className="img-fluid" />
          </Col>
          <Col lg={{ span: 3, offset: 2, order: 1 }} md={{ span: 5, offset: 1, order: 2 }}>
            <div className="h3 font-weight-bold mt-3">Assess Your Need</div>
            <p>
              Our specialists work directly with Principals, Administrators, and Faculty to understand the needs of your
              College or University to build a plan for success.
            </p>

            <Link to="/request-for-more-info/" className="btn btn-success">
              Speak with an Expert
            </Link>
          </Col>
        </Row>
      </article>
    </section>

    <section id="customized-plan-and-conduct-training" className="my-2">
      <article className="container-fluid">
        <Row className="align-items-center">
          <Col lg={{ span: 5 }} md={{ span: 5 }}>
            <img src="/images/customized-plan.webp" alt="images" className="img-fluid" />
          </Col>
          <Col lg={{ span: 4, offset: 2 }} md={{ span: 5, offset: 1 }}>
            <div className="">
              <div className="h3 font-weight-bold mt-3">Create a Customized Plan and Conduct Training</div>
              <p>
                We help to develop a strategic plan and curated training program specifically designed for your
                administrators, faculty, and students. In this phase, we help Colleges and Universities successfully
                adopt the fuse|classroom Platform.
              </p>

              <Link to="/request-for-more-info/" className="btn btn-success">
                Speak with an Expert
              </Link>
            </div>
          </Col>
        </Row>
      </article>
    </section>

    <section id="ensure-successful-adoption" className="my-2">
      <article className="container-fluid">
        <Row className="align-items-center">
          <Col lg={{ span: 5, offset: 2, order: 2 }} md={{ span: 5, order: 2 }}>
            <img src="/images/successful-adoption.webp" alt="images" className="img-fluid" />
          </Col>
          <Col lg={{ span: 3, offset: 2, order: 1 }} md={{ span: 5, offset: 1, order: 2 }}>
            <div className="h3 font-weight-bold mt-3">Ensure Successful Adoption</div>
            <p>
              To provide consistent support to our College and University partners, our specialists ensure the highest
              quality online experience, even as needs change and evolve.
            </p>

            <Link to="/request-for-more-info/" className="btn btn-success">
              Speak with an Expert
            </Link>
          </Col>
        </Row>
      </article>
    </section>

    {/* call to action */}
    <section className="py-5">
      <article className="container">
        <Row className="justify-content-center">
          <Col lg="8">
            <div className="rounded border border-success p-4 text-center">
              <span className="d-inline-block mr-md-5 h5 font-weight-bold">Interesting in becoming a partner?</span>
              {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
              <Link
                to="/request-for-more-info/"
                className="btn btn-success"
                // onClick={() => FormEventAnalytics("Services_Bottom_Request_Demo")}
                id="request--for-more-info__bottom"
              >
                REQUEST FOR MORE INFO
              </Link>
            </div>
          </Col>
        </Row>
      </article>
    </section>
  </Layout>
)

export default Services
